// import logo from './logo.svg';
import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "./Theme.css";
import "./Reports.css";
import AnimatedRoutes from "./AnimatedRoutes";
import { GlobalData } from "./Components/GlobalData";
import axios from "./Components/AxiosInstance";
import SideBar from "./Components/SideBar/SideBar";
import LoggedInUser from "./Components/Shared_Components/LoggedInUser";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [currentTheme, setCurrentTheme] = useState(localStorage.getItem("theme") || "light");

  const [parties, setParties] = useState([]);
  //  const [proDescs, setProDescs] = useState([]);
  const [firm, setFirm] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [curBranch, setCurBranch] = useState([]);
  // eslint-disable-next-line
  const [userBranch, setUserBranch] = useState(0);
  // eslint-disable-next-line
  const [IsMultiBranch, setIsMultiBranch] = useState(false);
  const [banks, setBanks] = useState([]);
  const [cashlist, setCashlist] = useState([]);
  const [cashParties, setCashParties] = useState([]);

  const [yarnProducts, setYarnProducts] = useState([]);
  const [grProducts, setGrProducts] = useState([]);

  const [products, setProducts] = useState([]);
  const [prodTypes, setProdTypes] = useState([]);
  const [prodCategs, setProdCategs] = useState([]);
  const [partyMHeads, setPartyMHeads] = useState([]);
  const [partyHeads, setPartyHeads] = useState([]);
  const [partyCategs, setPartyCategs] = useState([]);
  const [partyTypes, setPartyTypes] = useState([]);
  const [cpbballist, setCpbBalList] = useState([]);
  const [salemanlist, setSalemanList] = useState([]);
  const [acSettings, setAcSettings] = useState([]);
  const [digCateg, setDigCateg] = useState([]);
  const [token, setToken] = useState("");


  useEffect(() => {
    if ((localStorage.getItem("theme") || "") === "dark") {
      setCurrentTheme("dark");
      document.querySelector("body").setAttribute("data-theme", "dark");
    } else {
      setCurrentTheme("light");
      document.querySelector("body").setAttribute("data-theme", "light");
    }
  }, []);

  function getDataApi() {
    axios.get("api/date/session_date").then((response) => {
      localStorage.setItem("sDate", response.data[0]?.sDate?.slice(0, 10));
    });

    // axios.get("api/product/prodesclist").then((response) => {
    //   setProDescs(response.data);
    // });

    axios.get("api/company/complist").then((response) => {
      setCompanies(response.data);
      localStorage.setItem("Decimals", response.data[0]?.Decimals || 0);
      localStorage.getItem("BranchId") || localStorage.setItem("BranchId", 1);
      !response.data[0].IsMultiBranch &&
        localStorage.setItem("BranchName", response.data[0]?.label);
      if (response.data[0].IsMultiBranch) {
        setIsMultiBranch(true);

        axios.get("api/company/branchlist").then((response) => {
          setBranches(response.data);
          // const tmpBranches = response.data;

          axios.get("api/perm/users/getbranchid").then((response) => {
            // setUserBranch(response.data);
            // if (response.data > 0) {
            //   let branchName = tmpBranches.filter((item) => item.value === response.data)[0].label;
            //   setCurBranch({ BranchId: response.data, BranchName: branchName });
            // }
          });
        });
      }
    });
    axios.get("api/perm/acsettings").then((response) => {
      setAcSettings(response.data);
    });
    axios.get("api/party/partieslist").then((response) => {
      setParties(response.data);
    });
    axios.get("api/firm").then((response) => {
      setFirm(response.data);
    });
    axios.get("api/digcateg/categlist").then((response) => {
      setDigCateg(response.data);
    });
    axios.get("api/bank/bankslist").then((response) => {
      response.data.length > 0 && setBanks(response.data);
    });
    axios.get("api/party/cashlist").then((response) => {
      response.data.length > 0 && setCashlist(response.data);
    });
    axios.get("api/product/productslist").then((response) => {
      response.data.length > 0 && setProducts(response.data);
    });
    axios.get("api/prodtype/prodtypeslist").then((response) => {
      response.data.length > 0 && setProdTypes(response.data);
    });
    axios.get("api/prodcateg/prodcategslist").then((response) => {
      response.data.length > 0 && setProdCategs(response.data);
    });
    axios.get("api/partymhead/partymheadslist").then((response) => {
      response.data.length > 0 && setPartyMHeads(response.data);
    });
    axios.get("api/partyhead/partyheadslist").then((response) => {
      response.data.length > 0 && setPartyHeads(response.data);
    });
    axios.get("api/partycateg/partycategslist").then((response) => {
      response.data.length > 0 && setPartyCategs(response.data);
    });
    axios.get("api/partytype/partytypeslist").then((response) => {
      response.data.length > 0 && setPartyTypes(response.data);
    });
    axios
      .get(`api/cpb/cpb_bal/${curBranch?.BranchId || 1}`)
      .then((response) => {
        response.data.length > 0 && setCpbBalList(response.data);
      });
    axios.get("api/party/salemanlist").then((response) => {
      response.data.length > 0 && setSalemanList(response.data);
    });
  }

  function ClearApiData() {
    setCompanies([]);
    setBranches([]);
    setYarnProducts([]);
    setGrProducts([]);
    setProducts([]);
    setParties([]);
    setFirm([]);
    setDigCateg([]);
    setBanks([]);
    setCashlist([]);
    setProdTypes([]);
    setProdCategs([]);
    setPartyMHeads([]);
    setPartyHeads([]);
    setPartyCategs([]);
    setPartyTypes([]);
    setCpbBalList([]);
    setSalemanList([]);
    localStorage.clear();
  }

  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("menus")) {
      let MenuData = JSON.parse(localStorage.getItem("menus"));
      setMenu(MenuData);

      let token = JSON.parse(localStorage.getItem("user"))?.Token;

      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  }, []);

  useEffect(() => {
    if (menu.length > 0) {
      getDataApi();
    }
    // eslint-disable-next-line
  }, [menu]);

  //JSX
  return (
    <div className="App">
      <Router>
        <GlobalData.Provider
          value={{
            currentTheme,
            setCurrentTheme,
            menu,
            companies,
            branches,
            curBranch,
            cashParties,
            setCashParties,
            setCurBranch,
            userBranch,
            token,
            parties,
            firm,
            digCateg,
            banks,
            cashlist,
            yarnProducts,
            setYarnProducts,
            grProducts,
            setGrProducts,
            products,
            prodTypes,
            prodCategs,
            cpbballist,
            partyMHeads,
            partyHeads,
            partyCategs,
            partyTypes,
            salemanlist,
            acSettings,
          }}
        >
          {/* <LoggedInUser /> */}
          {localStorage.getItem("user") && (
            <div style={{ position: "fixed", zIndex: 2 }}>
              <SideBar
                menu={menu}
                setMenu={setMenu}
                ClearApiData={ClearApiData}
              />
            </div>
          )}
          <div style={{ position: "relative", zIndex: 1 }}>
            <LoggedInUser
              getDataApi={getDataApi}
              branches={branches}
            />
            <AnimatedRoutes setMenu={setMenu} setToken={setToken} />
          </div>
        </GlobalData.Provider>
      </Router>
    </div>
  );
}

export default App;
