import axios from "axios";

const instance = axios.create({
  // baseURL: "https://103.55.70.106:444/", //f e 

  baseURL: process.env.REACT_APP_API_URL,  //f e 
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token")

    if (token) {
      config.headers.Authorization = `Bearer ${token.replace(/"/g, '')}`;
      config.headers.CompanyId = window.localStorage.getItem("companyId");
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
