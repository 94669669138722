import BranchList from "../Comps/BranchList";
import GetUser from '../Shared_Components/GetUser';
import { FaSun, FaMoon } from "react-icons/fa";
import { useContext } from 'react';
import { GlobalData } from "../GlobalData";


export default function LoggedInUser({ getDataApi }) {

  const datacontext = useContext(GlobalData);
  const isMultiBranch = datacontext?.companies[0]?.IsMultiBranch;
  let userName = GetUser();
  const toggleTheme = () => {
    if (localStorage.getItem("theme") === "dark") {
      datacontext?.setCurrentTheme("light");
      localStorage.setItem("theme", "light");
      document.querySelector("body").setAttribute('data-theme', 'light');
    } else {
      datacontext?.setCurrentTheme("dark");
      localStorage.setItem("theme", "dark");
      document.querySelector("body").setAttribute('data-theme', 'dark');
    }
  };
  return (
    <div>
      {userName && (
        <div className="loggedinuser d-flex w-100">
          <div style={{width: "100px" }} className="mx-3">User: {userName}</div>
            <div className={isMultiBranch ? "mr-3" : ""} style={{width: isMultiBranch && "200px" }}>
              <BranchList />
            </div>
          <div>
            <button onClick={getDataApi}>Refresh</button>
          </div>
          <div className="ml-auto mr-5 cursor-pointer">
            <span onClick={toggleTheme}>{datacontext?.currentTheme === "light" ? <FaMoon size={25} color="#2c3e50" /> : <FaSun size={28} color="#f39c12" />}</span>
          </div>
        </div>
      )}
    </div>
  );
};
