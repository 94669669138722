import { Routes, Route, useLocation } from "react-router-dom";
import loadable from "@loadable/component";
import { useCompType } from "./Components/Shared_Components/Util";

//Dig
const GPV = loadable(() => import("./Components/Forms/GrEntries/GPV/GPV"));
const KP = loadable(() => import("./Components/Forms/GrEntries/KP/KP"));
const PP = loadable(() => import("./Components/Forms/GrEntries/PP/PP"));
const GrIGP = loadable(() => import("./Components/Forms/GrEntries/GrIGP/GrIGP"));
const WGTRans = loadable(() => import("./Components/Forms/WvEntries/WGTrans/WGTrans"));
const WGIn = loadable(() => import("./Components/Forms/WvEntries/WGIn/WGIn"));
const WGOut = loadable(() =>
  import("./Components/Forms/WvEntries/WGOut/WGOut")
);
const GrCont = loadable(() =>
  import("./Components/Reports/WvReps/StkReps/GrCont/GrCont")
);
const GrPurch = loadable(() =>
  import("./Components/Reports/WvReps/StkReps/GrPurch/GrPurch")
);
const GrRec = loadable(() =>
  import("./Components/Reports/WvReps/StkReps/GrRec/GrRec")
);
const YarnPur = loadable(() =>
  import("./Components/Reports/WvReps/StkReps/YarnPur/YarnPur")
);
const YarnTransfer = loadable(() =>
  import("./Components/Reports/WvReps/TransReps/YarnTrans/YarnTransfer")
);
const YarnOut = loadable(() =>
  import("./Components/Reports/WvReps/YarnOut/YarnOut")
);
const GrPur = loadable(() => import("./Components/Reports/GrReps/GrPur/GrPur"));
const GrIss = loadable(() => import("./Components/Reports/GrReps/GrIss/GrIss"));
const FSal = loadable(() => import("./Components/Reports/GrReps/FSal/FSal"));
const StockInTransit = loadable(() => import("./Components/Forms/StkEntries/OGP/StockInTransit/StockInTransit"));
const StockAtYard = loadable(() => import("./Components/Forms/StkEntries/OGP/StockAtYard/StockAtYard"));

const CP = loadable(() => import("./Components/Forms/AcEntries/CP/CP"));
const CR = loadable(() => import("./Components/Forms/AcEntries/CR/CR"));
const Forgot = loadable(() => import("./Components/Forgot/Forgot"));
const AcStat = loadable(() =>
  import("./Components/Reports/AcReps/AcStat/AcStat")
);
const JV = loadable(() => import("./Components/Forms/AcEntries/JV/JV"));
const Login = loadable(() => import("./Components/Login/Login"));
const GoogleLogin = loadable(() => import("./Components/Login/GoogleLogin"));
const GooglePage = loadable(() => import("./Components/Login/GooglePage"));
const Register = loadable(() => import("./Components/Register/Register"));
// const Test from './Components/Test'))
const Home = loadable(() => import("./Components/Home"));
const PrintV = loadable(() => import("./Components/Reports/Shared/PrintV"));
const Trial = loadable(() =>
  import("./Components/Reports/AcReps/Trial/Trial")
);
const Protected = loadable(() => import("./Components/Protected/Protected"));
const DailyReg = loadable(() =>
  import("./Components/Reports/AcReps/DailyReg/DailyReg")
);
const DynamicSearch = loadable(() =>
  import("./Components/Forms/_Shared/DynamicSearch/DynamicSearch")
);
const Management = loadable(() =>
  import("./Components/Reports/AcReps/Management/Management")
);
const DailyRegAudit = loadable(() =>
  import("./Components/Reports/AcReps/DailyRegAudit/DailyRegAudit")
);
const CPB_Bals = loadable(() =>
  import("./Components/Reports/AcReps/CPB_Bals/CPB_Bals")
);
const CPBLeg = loadable(() =>
  import("./Components/Reports/AcReps/CPBLeg/CPBLeg")
);
const AgingDr = loadable(() =>
  import("./Components/Reports/AcReps/AgingDr/AgingDr")
);
const Recables = loadable(() =>
  import("./Components/Reports/AcReps/Recables/Recables")
);
const Payables = loadable(() =>
  import("./Components/Reports/AcReps/Payables/Payables")
);
const DashBoard = loadable(() =>
  import("./Components/Reports/DashBoards/DashBoard")
);
const ProdLeg = loadable(() =>
  import("./Components/Reports/StkReps/ProdLeg/ProdLeg")
);
const YardProdLeg = loadable(() =>
  import("./Components/Reports/StkReps/YardProdLeg/YardProdLeg")
);
const GodownStkLevel = loadable(() =>
  import("./Components/Reports/WvReps/StkReps/GodownStkLevel/GodownStkLevel")
);
const YarnStkLevel = loadable(() =>
  import("./Components/Reports/WvReps/StkReps/YarnStkLevel/YarnStkLevel")
);
const WeavingStkLevel = loadable(() =>
  import("./Components/Reports/WvReps/StkReps/WeavingStkLevel/WeavingStkLevel")
);
const PendContracts = loadable(() =>
  import("./Components/Reports/WvReps/StkReps/PendContracts/PendContracts")
);
const GreigeStkLevel = loadable(() =>
  import("./Components/Reports/WvReps/StkReps/GreigeStkLevel/GreigeStkLevel")
);
const StkLevel = loadable(() =>
  import("./Components/Reports/StkReps/StkLevel/StkLevel")
);
const StkEval = loadable(() =>
  import("./Components/Reports/StkReps/StkEval/StkEval")
);
const GrStk = loadable(() => import("./Components/Reports/GrReps/GrStk/GrStk"));
const GrInOutStk = loadable(() =>
  import("./Components/Reports/GrReps/GrInOutStk/GrInOutStk")
);
const IGP = loadable(() => import("./Components/Forms/StkEntries/IGP/IGP"));
const ProductCoding = loadable(() =>
  import("./Components/Forms/Accounts/ProductCoding/ProductCoding")
);
const PartyCoding = loadable(() =>
  import("./Components/Forms/Accounts/PartyCoding/PartyCoding")
);
const PartyTypeCoding = loadable(() =>
  import("./Components/Forms/Accounts/PartyTypeCoding/PartyTypeCoding")
);
const PartyOpening = loadable(() =>
  import("./Components/Forms/Accounts/PartyOpening/PartyOpening")
);
const CpbOpening = loadable(() =>
  import("./Components/Forms/Accounts/CpbOpening/CpbOpening")
);
const StockOpening = loadable(() =>
  import("./Components/Forms/Accounts/StockOpening/StockOpening")
);
const YarnOpening = loadable(() =>
  import("./Components/Forms/Accounts/YarnOpening/YarnOpening")
);
const DigOpening = loadable(() =>
  import("./Components/Forms/Accounts/DigOpening/DigOpening")
);
const PoOpening = loadable(() =>
  import("./Components/Forms/Accounts/PoOpening/PoOpening")
);
const SaleCom = loadable(() =>
  import("./Components/Forms/Accounts/SaleCom/SaleCom")
);
const StkSal = loadable(() =>
  import("./Components/Reports/StkReps/StkSal/StkSal")
);
const WGrProc = loadable(() =>
  import("./Components/Reports/WvReps/TransReps/WGrProc/WGrProc")
);
const GrSal = loadable(() =>
  import("./Components/Reports/WvReps/TransReps/GrSal/GrSal")
);
const WvAudit = loadable(() =>
  import("./Components/Reports/WvReps/TransReps/WvAudit/WvAudit")
);
const WvStkPL = loadable(() =>
  import("./Components/Reports/WvReps/TransReps/WvStkPL/WvStkPL")
);
const StkPL = loadable(() =>
  import("./Components/Reports/StkReps/StkPL/StkPL")
);
const DigPLPOS = loadable(() =>
  import("./Components/Reports/DigReps/DigPLPOS/DigPLPOS")
);
const DigPL = loadable(() =>
  import("./Components/Reports/DigReps/DigPL/DigPL")
);
const DigOut = loadable(() =>
  import("./Components/Reports/DigReps/DigOut/DigOut")
);
const StkPur = loadable(() =>
  import("./Components/Reports/StkReps/StkPur/StkPur")
);
const Trial2 = loadable(() =>
  import("./Components/Reports/AcReps/Trial2/Trial2")
);
const AcPL = loadable(() => import("./Components/Reports/AcReps/AcPL/AcPL"));
const BS = loadable(() => import("./Components/Reports/AcReps/BS/BS"));
const ProdTypeCoding = loadable(() =>
  import("./Components/Forms/Accounts/ProdTypeCoding/ProdTypeCoding")
);
const UnitsCoding = loadable(() => import("./Components/Forms/Accounts/UnitsCoding/UnitsCoding"));
const BrokersCoding = loadable(() => import("./Components/Forms/Accounts/BrokersCoding/BrokersCoding"));
const FileUpload = loadable(() => import("./Components/Forms/UploadFiles/FileUpload"));
const BS2 = loadable(() => import("./Components/Reports/AcReps/BS/BS2"));
const DrDues = loadable(() => import("./Components/Reports/AcReps/DrDues/DrDues"));
const CrDues = loadable(() => import("./Components/Reports/AcReps/CrDues/CrDues"));
const Journal = loadable(() => import("./Components/Prints/AcPrints/LegPrint"));
const CHQPrint = loadable(() =>
  import("./Components/Prints/AcPrints/CHQPrint")
);
const Ana = loadable(() => import("./Components/Reports/StkReps/Ana/Ana"));
const IP = loadable(() => import("./Components/IP"));
const StkPendPO = loadable(() =>
  import("./Components/Reports/StkReps/StkPO/StkPendPO")
);
const Users = loadable(() => import("./Components/Forms/Perm/Users/Users"));
const UserRoles = loadable(() =>
  import("./Components/Forms/Perm/UserRoles/UserRoles")
);
const UserMenus = loadable(() =>
  import("./Components/Forms/Perm/UserMenus/UserMenus")
);
const UserRoleMenus = loadable(() =>
  import("./Components/Forms/Perm/UserRoleMenus/UserRoleMenus")
);
const PrintAcStat = loadable(() =>
  import("./Components/Prints/AcPrints/PrintAcStat/PrintAcStat")
);
const UserParties = loadable(() =>
  import("./Components/Forms/Perm/UserParties/UserParties")
);
const GetUserName = loadable(() => import("./Components/GetUserName"));
const ByPassCrLimit = loadable(() =>
  import("./Components/Forms/Perm/ByPassCrLimit/ByPassCrLimit")
);
const AcSettings = loadable(() =>
  import("./Components/Forms/Perm/AcSettings/AcSettings")
);
const AgingCr = loadable(() =>
  import("./Components/Reports/AcReps/AgingCr/AgingCr")
);
const IPAddresses = loadable(() =>
  import("./Components/Forms/Perm/IPAddresses/IPAddresses")
);
const TestForm = loadable(() => import("./Components/Test/TestForm"));
const FileUploader = loadable(() =>
  import("./Components/Forms/UploadFiles/FileUploader")
);
const SRV = loadable(() => import("./Components/Forms/StkEntries/SRV/SRV"));
const SO = loadable(() => import("./Components/Forms/StkEntries/SO/SO"));
const SOLeg = loadable(() => import("./Components/Forms/StkEntries/SO/SOLeg"));
const SOBal = loadable(() => import("./Components/Forms/StkEntries/SO/SOBal"));
const OGPBal = loadable(() => import("./Components/Forms/StkEntries/OGP/OGPBal/OGPBal"));
const OGP = loadable(() => import("./Components/Forms/StkEntries/OGP/OGP"));
const SV = loadable(() => import("./Components/Forms/StkEntries/SV/SV"));
const SV2 = loadable(() => import("./Components/Forms/StkEntries/OGP/SV2"));
const POS = loadable(() => import("./Components/Forms/StkEntries/POS/POS"));
const PRV = loadable(() => import("./Components/Forms/StkEntries/PRV/PRV"));
const PV = loadable(() => import("./Components/Forms/StkEntries/PV/PV"));
const CHQ = loadable(() => import("./Components/Forms/AcEntries/CHQ/CHQ"));
const BOM = loadable(() => import("./Components/Forms/StkEntries/MF/BOM/BOM"));
const PO = loadable(() => import("./Components/Forms/StkEntries/PO/PO"));
const DIn = loadable(() => import("./Components/Forms/DigEntries/DIn/DIn"));
const DIGPack = loadable(() =>
  import("./Components/Forms/DigEntries/DIGPack/DIGPack")
);
const DigProc = loadable(() =>
  import("./Components/Forms/DigEntries/DigProc/DigProc")
);
const GodownLeg = loadable(() =>
  import("./Components/Reports/WvReps/LegReps/GodownLeg/GodownLeg")
);
const YarnLeg = loadable(() =>
  import("./Components/Reports/WvReps/LegReps/YarnLeg/YarnLeg")
);
const GreigeLeg = loadable(() =>
  import("./Components/Reports/WvReps/LegReps/GreigeLeg/GreigeLeg")
);
const ContStatus = loadable(() =>
  import("./Components/Reports/WvReps/StkReps/ContStatus/ContStatus")
);
const WeavingLeg = loadable(() =>
  import("./Components/Reports/WvReps/LegReps/WeavingLeg/WeavingLeg")
);
const POLeg = loadable(() =>
  import("./Components/Reports/StkReps/POLeg/POLeg")
);
const DigProdLeg = loadable(() =>
  import("./Components/Reports/DigReps/DigProdLeg/DigProdLeg")
);
const DigProdLegPOS = loadable(() =>
  import("./Components/Reports/DigReps/DigProdLegPOS/DigProdLegPOS")
);
const DIGRateListSum = loadable(() =>
  import("./Components/Forms/DigEntries/POS/DIGRateListSum/DIGRateListSum")
);
const DOut = loadable(() => import("./Components/Forms/DigEntries/DOut/DOut"));

const GIV = loadable(() => import("./Components/Forms/GrEntries/GIV/GIV"));
const YPC = loadable(() => import("./Components/Forms/WvEntries/YPC/YPC"));
const YIN = loadable(() => import("./Components/Forms/WvEntries/YIN/YIN"));
const YOUT = loadable(() => import("./Components/Forms/WvEntries/YOUT/YOUT"));
const YTV = loadable(() => import("./Components/Forms/WvEntries/YTV/YTV"));
const WGC = loadable(() => import("./Components/Forms/WvEntries/WGC/WGC"));
const YarnIn = loadable(() => import("./Components/Forms/YrEntries/YarnIn/YarnIn"));
const YYarnOut = loadable(() => import("./Components/Forms/YrEntries/YarnOut/YarnOut"));
const GrInOut = loadable(() => import("./Components/Forms/ArEntries/GrInOut/GrInOut"));
const WGW = loadable(() => import("./Components/Forms/WvEntries/WGW/WGW"));
const WSB = loadable(() => import("./Components/Forms/WvEntries/WSB/WSB"));
const WGCClosing = loadable(() => import("./Components/Forms/WvEntries/WGC/WGCClosing"));
const WGrLotLeg = loadable(() =>
  import("./Components/Reports/WvReps/LegReps/WGrLotLeg/WGrLotLeg")
);
const Jobs = loadable(() =>
  import("./Components/Forms/StkEntries/MF/Jobs/Jobs")
);
const FProduction = loadable(() =>
  import("./Components/Forms/GrEntries/FProduction/FProduction")
);
const GrOUT = loadable(() =>
  import("./Components/Forms/GrEntries/GrOut/GrOut")
);
const ProductImg = loadable(() =>
  import("./Components/Forms/Accounts/ProductCoding/ProductImg")
);
const PartyCategCoding = loadable(() =>
  import("./Components/Forms/Accounts/PartyCategCoding/PartyCategCoding")
);
const PartyHeadCoding = loadable(() =>
  import("./Components/Forms/Accounts/PartyHeadCoding/PartyHeadCoding")
);
const DigCategCoding = loadable(() =>
  import("./Components/Forms/Accounts/DigCategCoding/DigCategCoding")
);
const StudentCoding = loadable(() =>
  import("./Components/Forms/ScForms/StudentCoding/StudentCoding")
);
const CourseCoding = loadable(() =>
  import("./Components/Forms/ScForms/CourseCoding/CourseCoding")
);
const ShiftCoding = loadable(() =>
  import("./Components/Forms/ScForms/ShiftCoding/ShiftCoding")
);
const StudentFees = loadable(() =>
  import("./Components/Forms/ScForms/StudentFees/StudentFees")
);
const MemberCoding = loadable(() =>
  import("./Components/Forms/MMForms/MemberCoding/MemberCoding")
);
const MemberPayments = loadable(() =>
  import("./Components/Forms/MMForms/MemberPayments/MemberPayments")
);
const PartyMHeadCoding = loadable(() =>
  import("./Components/Forms/Accounts/PartyMHeadCoding/PartyMHeadCoding")
);
const COA = loadable(() => import("./Components/Reports/AcReps/COA/COA"));
const GoodsCoding = loadable(() =>
  import("./Components/Forms/Accounts/GoodsCoding/GoodsCoding")
);
const ProdCategCoding = loadable(() =>
  import("./Components/Forms/Accounts/ProdCategCoding/ProdCategCoding")
);
const IOA = loadable(() => import("./Components/Reports/StkReps/IOA/IOA"));
const DigRateList = loadable(() =>
  import("./Components/Forms/DigEntries/POS/DIGRateList/DIGRateList")
);
const DigPur = loadable(() =>
  import("./Components/Reports/DigReps/DigPur/DigPur")
);
const DigSal = loadable(() =>
  import("./Components/Reports/DigReps/DigSal/DigSal")
);
const DigProcPendBills = loadable(() =>
  import("./Components/Reports/DigReps/DigProcPendBills/DigProcPendBills")
);
const DigStkLevel = loadable(() =>
  import("./Components/Reports/DigReps/DigStkLevel/DigStkLevel")
);
const DigStkLevelPOS = loadable(() =>
  import("./Components/Reports/DigReps/DigStkLevelPOS/DigStkLevelPOS")
);
const DigIdLeg = loadable(() =>
  import("./Components/Reports/DigReps/DigIdLeg/DigIdLeg")
);
const DigProcLeg = loadable(() =>
  import("./Components/Reports/DigReps/DigProcLeg/DigProcLeg")
);
const GrLotLeg = loadable(() =>
  import("./Components/Reports/GrReps/GrLotLeg/GrLotLeg")
);
const DigBarCodeLeg = loadable(() =>
  import("./Components/Reports/DigReps/DigBarCodeLeg/DigBarCodeLeg")
);
const DigBarCodeLegPOS = loadable(() =>
  import("./Components/Reports/DigReps/DigBarCodeLegPOS/DigBarCodeLegPOS")
);

const GrButtons = loadable(() =>
  import("./Components/Forms/GrEntries/_Shared/GrButtons")
);

const LoomCoding = loadable(() =>
  import("./Components/Forms/GrEntries/Accounts/LoomCoding/LoomCoding")
);
const SupplierCoding = loadable(() =>
  import("./Components/Forms/GrEntries/Accounts/SupplierCoding/SupplierCoding")
);
const MendTypeCoding = loadable(() =>
  import("./Components/Forms/GrEntries/Accounts/MendTypeCoding/MendTypeCoding")
);

export default function AnimatedRoutes({ setMenu }) {
  const location = useLocation();

  return (
    <Routes key={location.pathname.split("/")[1]} location={location}>
      <Route path="/signup" element={<Register />} />
      <Route path="/login" element={<Login setMenu={setMenu} />} />
      <Route path="/googlelogin" element={<GoogleLogin setMenu={setMenu} />} />
      <Route path="/googlepage" element={<GooglePage setMenu={setMenu} />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/journal" element={<Journal />} />
      <Route path="/cheque" element={<CHQPrint />} />
      <Route path="/accst" element={<PrintAcStat />} />
      <Route path="/fileupload" element={<FileUpload />} />

      {/* Digital */}
      <Route path="/mendtypecoding" element={<MendTypeCoding />} />
      <Route path="/suppliercoding" element={<SupplierCoding />} />
      <Route path="/loomcoding" element={<LoomCoding />} />
      <Route path="/grbuttons" element={<GrButtons />} />
      <Route path="/gpv/:id" element={<GPV />} />
      <Route path="/kp/:id" element={<KP />} />
      <Route path="/pp/:id" element={<PP />} />
      <Route path="/grigp/:id" element={<GrIGP />} />
      <Route path="/wgt/:id" element={<WGTRans />} />
      <Route path="/wgpo/:id" element={<WGIn />} />
      <Route path="/wgp/:id" element={<WGIn />} />
      <Route path="/wgs/:id" element={<WGOut />} />
      <Route path="/grcont" element={<GrCont />} />
      <Route path="/grpur" element={<GrPur />} />
      <Route path="/griss" element={<GrIss />} />
      <Route path="/fsal" element={<FSal />} />
      <Route path="/dpvo/:id" element={<DIn />} />
      <Route path="/digp/:id" element={<DIn />} />
      <Route path="/dpv/:id" element={<DIn />} />
      <Route path="/orv/:id" element={<DIn />} />
      <Route path="/otv/:id" element={<DIn />} />
      <Route path="/tr1/:id" element={<DIGPack />} />
      <Route path="/tr2/:id" element={<DIGPack />} />
      <Route path="/prc/:id" element={<DigProc />} />
      <Route path="/dsv/:id" element={<DOut />} />
      <Route path="/dprv/:id" element={<DOut />} />
      <Route path="/dprv2/:id" element={<DOut />} />
      <Route path="/dsv2/:id" element={<DOut />} />
      <Route path="/dcon/:id" element={<DOut />} />
      <Route
        path="/digratelist"
        element={<Protected Component={DigRateList} />}
      />
      <Route
        path="/digratelistsum"
        element={<Protected Component={DIGRateListSum} />}
      />
      <Route path="/giv/:id" element={<GIV />} />
      <Route path="/ypc/:id" element={<YPC />} />
      <Route path="/ypv/:id" element={<YIN />} />
      <Route path="/ypvo/:id" element={<YIN />} />
      <Route path="/ysv/:id" element={<YOUT />} />
      <Route path="/ytv/:id" element={<YTV />} />
      <Route path="/wgc/:id" element={<WGC />} />
      <Route path="/wgco/:id" element={<WGC />} />
      <Route path="/gps/:id" element={<GrInOut />} />
      <Route path="/yso/:id" element={<YarnIn />} />
      <Route path="/ysp/:id" element={<YarnIn />} />
      <Route path="/yss/:id" element={<YYarnOut />} />
      <Route path="/wgw/:id" element={<WGW />} />
      <Route path="/wsb/:id" element={<WSB />} />
      <Route
        path="/adj/:vNo"
        element={<WGCClosing show={true} />}
      />
      <Route
        path="/adjo/:vNo"
        element={<WGCClosing show={true} />}
      />
      <Route path="/wgrlotleg" element={<WGrLotLeg />} />
      <Route path="/jobs/:id" element={<Jobs />} />
      <Route path="/fproduction/:id" element={<FProduction />} />
      <Route path="/fsv/:id" element={<GrOUT />} />
      <Route path="/digpur" element={<Protected Component={DigPur} />} />
      <Route
        path="/digprocpendbills"
        element={<Protected Component={DigProcPendBills} />}
      />
      <Route path="/digsal" element={<Protected Component={DigSal} />} />

      <Route
        path="/digidleg/:id"
        element={<Protected Component={DigIdLeg} />}
      />
      <Route
        path="/digprocleg/:id"
        element={<Protected Component={DigProcLeg} />}
      />
      <Route
        path="/grlotleg/:id"
        element={<Protected Component={GrLotLeg} />}
      />
      <Route
        path="/digbarcodeleg/:id"
        element={<Protected Component={DigBarCodeLeg} />}
      />
      <Route
        path="/digbarcodelegpos/:id"
        element={<Protected Component={DigBarCodeLegPOS} />}
      />
      <Route path="/godownleg" element={<Protected Component={GodownLeg} />} />
      <Route path="/yarnleg" element={<Protected Component={YarnLeg} />} />
      <Route
        path="/weavingleg"
        element={<Protected Component={WeavingLeg} />}
      />
      <Route path="/greigeleg" element={<Protected Component={GreigeLeg} />} />
      <Route path="/contstat" element={<Protected Component={ContStatus} />} />
      <Route path="/poleg/:id" element={<Protected Component={POLeg} />} />
      <Route
        path="/digprodleg"
        element={<Protected Component={DigProdLeg} />}
      />
      <Route
        path="/digprodlegpos"
        element={<Protected Component={DigProdLegPOS} />}
      />
      <Route
        path="/yardprodleg"
        element={<Protected Component={YardProdLeg} />}
      />
      <Route
        path="/digstklevel"
        element={<Protected Component={DigStkLevel} />}
      />
      <Route
        path="/digstklevelpos"
        element={<Protected Component={DigStkLevelPOS} />}
      />

      {/* Reports Routes */}
      <Route
        path="/management"
        element={<Protected Component={Management} />}
      />
      <Route path="/dailyreg" element={<Protected Component={DailyReg} />} />
      <Route
        path="/dailyregaudit"
        element={<Protected Component={DailyRegAudit} />}
      />
      <Route path="/dr" element={<Protected Component={DailyReg} />} />
      <Route
        path="/search/:tbl"
        element={<Protected Component={DynamicSearch} />}
      />
      <Route path="/cpb_bals" element={<Protected Component={CPB_Bals} />} />
      <Route path="/cpbleg/:id" element={<Protected Component={CPBLeg} />} />
      <Route path="/agingdr" element={<Protected Component={AgingDr} />} />
      <Route path="/agingcr" element={<Protected Component={AgingCr} />} />
      <Route path="/recables" element={<Protected Component={Recables} />} />
      <Route path="/payables" element={<Protected Component={Payables} />} />
      <Route path="/prodleg" element={<Protected Component={ProdLeg} />} />
      <Route
        path="/godownstk"
        element={<Protected Component={GodownStkLevel} />}
      />
      <Route path="/yarnstk" element={<Protected Component={YarnStkLevel} />} />
      <Route
        path="/weavingstk"
        element={<Protected Component={WeavingStkLevel} />}
      />
      <Route
        path="/pendcontracts"
        element={<Protected Component={PendContracts} />}
      />
      <Route
        path="/greigestk"
        element={<Protected Component={GreigeStkLevel} />}
      />
      <Route path="/stklevel" element={<Protected Component={StkLevel} />} />
      <Route path="/stkeval" element={<Protected Component={StkEval} />} />
      <Route path="/grstk" element={<GrStk />} />
      <Route path="/grinoutstk" element={<GrInOutStk />} />

      <Route path="/stksal" element={<Protected Component={StkSal} />} />
      <Route path="/wgrproc" element={<Protected Component={WGrProc} />} />
      <Route path="/grsal" element={<Protected Component={GrSal} />} />
      <Route path="/digpl" element={<Protected Component={DigPL} />} />
      <Route path="/digplpos" element={<Protected Component={DigPLPOS} />} />
      <Route path="/digout" element={<Protected Component={DigOut} />} />
      <Route path="/wvaudit" element={<Protected Component={WvAudit} />} />
      <Route path="/wvstkpl" element={<Protected Component={WvStkPL} />} />
      <Route path="/stkpl" element={<Protected Component={StkPL} />} />
      <Route path="/stkpur" element={<Protected Component={StkPur} />} />
      <Route path="/grpurch" element={<Protected Component={GrPurch} />} />
      <Route path="/grrec" element={<Protected Component={GrRec} />} />
      <Route path="/yarnpur" element={<Protected Component={YarnPur} />} />
      <Route
        path="/yarntrans"
        element={<Protected Component={YarnTransfer} />}
      />
      <Route path="/yarnout" element={<Protected Component={YarnOut} />} />
      <Route path="/stkpendpo" element={<Protected Component={StkPendPO} />} />

      <Route path="/ioa" element={<Protected Component={IOA} />} />
      <Route path="/coa" element={<Protected Component={COA} />} />
      <Route path="/trial" element={<Protected Component={Trial} />} />
      <Route path="/trial2" element={<Protected Component={Trial2} />} />
      <Route path="/acpl" element={<Protected Component={AcPL} />} />
      <Route path="/bs" element={<Protected Component={BS} />} />
      <Route path="/bs2" element={<Protected Component={BS2} />} />
      <Route path="/drdues" element={<Protected Component={DrDues} />} />
      <Route path="/crdues" element={<Protected Component={CrDues} />} />
      <Route path="/acstat" element={<Protected Component={AcStat} />} />
      <Route
        path="/printv/:type/:vocno"
        element={<Protected Component={PrintV} />}
      />

      <Route path="/ana" element={<Protected Component={Ana} />} />

      <Route path="/users" element={<Protected Component={Users} />} />
      <Route path="/userroles" element={<Protected Component={UserRoles} />} />
      <Route path="/usermenus" element={<Protected Component={UserMenus} />} />
      <Route
        path="/userrolemenus"
        element={<Protected Component={UserRoleMenus} />}
      />
      <Route
        path="/userparties"
        element={<Protected Component={UserParties} />}
      />
      <Route
        path="/bypasscrlimit"
        element={<Protected Component={ByPassCrLimit} />}
      />
      <Route
        path="/ipaddress"
        element={<Protected Component={IPAddresses} />}
      />
      <Route
        path="/acsettings"
        element={<Protected Component={AcSettings} />}
      />

      <Route
        path="/getusername"
        element={<Protected Component={GetUserName} />}
      />

      {/* Reports Routes */}

      {/* Dashboard */}
      <Route path="/" element={<Protected Component={Home} />} />
      <Route path="/stkdash" element={<Protected Component={DashBoard} />} />

      {/* JV,BP,BR */}
      <Route path="/jv/:id" element={<Protected Component={JV} />} />
      <Route path="/br/:id" element={<Protected Component={JV} />} />
      <Route path="/bp/:id" element={<Protected Component={JV} />} />
      <Route path="/chq/:id" element={<Protected Component={CHQ} />} />
      <Route path="/cr/:id" element={<Protected Component={CR} />} />
      <Route path="/cp/:id" element={<Protected Component={CP} />} />

      {/* Manufacturing */}
      <Route path="/bom/:id" element={<Protected Component={BOM} />} />

      {/* Purchase */}
      <Route path="/po/:id" element={<Protected Component={PO} />} />
      <Route path="/pv/:id" element={<Protected Component={PV} />} />
      <Route path="/prv/:id" element={<Protected Component={PRV} />} />

      {/* Sale */}
      <Route
        path="/sv/:id"
        element={<Protected Component={useCompType() === "COAL" ? SV2 : SV} />}
      />
      <Route path="/pos/:id" element={<Protected Component={POS} />} />
      <Route path="/srv/:id" element={<Protected Component={SRV} />} />
      <Route path="/so/:id" element={<Protected Component={SO} />} />
      <Route path="/so_leg/:id" element={<Protected Component={SOLeg} />} />
      <Route path="/so_bal" element={<Protected Component={SOBal} />} />
      <Route path="/ogp/:id" element={<Protected Component={OGP} />} />
      <Route path="/ogp_bal" element={<Protected Component={OGPBal} />} />
      <Route path="/stockintransit" element={<Protected Component={StockInTransit} />} />
      <Route path="/stockatyard" element={<Protected Component={StockAtYard} />} />
      <Route path="/igp/:id" element={<Protected Component={IGP} />} />
      <Route path="/testform" element={<Protected Component={TestForm} />} />

      {/* ProductCoding */}
      <Route
        path="/prodcoding"
        element={<Protected Component={ProductCoding} />}
      />
      <Route path="/prodimg" element={<Protected Component={ProductImg} />} />
      {/* PartyCoding */}
      <Route
        path="/partycoding"
        element={<Protected Component={PartyCoding} />}
      />
      {/* PartyCoding */}

      {/* PartyMHead */}
      <Route
        path="/digcategcoding"
        element={<Protected Component={DigCategCoding} />}
      />
      <Route
        path="/studentcoding"
        element={<Protected Component={StudentCoding} />}
      />
      <Route
        path="/coursecoding"
        element={<Protected Component={CourseCoding} />}
      />
      <Route
        path="/shiftcoding"
        element={<Protected Component={ShiftCoding} />}
      />
      <Route
        path="/sr/:id"
        element={<Protected Component={StudentFees} />}
      />
      <Route
        path="/membercoding"
        element={<Protected Component={MemberCoding} />}
      />
      <Route
        path="/mr/:id"
        element={<Protected Component={MemberPayments} />}
      />
      {/* PartyMHead */}
      <Route
        path="/partymheadcoding"
        element={<Protected Component={PartyMHeadCoding} />}
      />
      {/* PartyHead */}
      <Route
        path="/partyheadcoding"
        element={<Protected Component={PartyHeadCoding} />}
      />
      {/* Partycateg */}
      <Route
        path="/partycategcoding"
        element={<Protected Component={PartyCategCoding} />}
      />
      {/* Partytype */}
      <Route
        path="/partytypecoding"
        element={<Protected Component={PartyTypeCoding} />}
      />
      <Route
        path="/goodscoding"
        element={<Protected Component={GoodsCoding} />}
      />
      <Route
        path="/prodcategcoding"
        element={<Protected Component={ProdCategCoding} />}
      />
      <Route
        path="/prodtypecoding"
        element={<Protected Component={ProdTypeCoding} />}
      />
      <Route path="/unitscoding" element={<Protected Component={UnitsCoding} />} />
      <Route path="/brokerscoding" element={<Protected Component={BrokersCoding} />} />
      {/* Partytype */}
      <Route path="/salecom" element={<Protected Component={SaleCom} />} />
      <Route path="/ip" element={<Protected Component={IP} />} />

      {/* Party Openong */}
      <Route
        path="/partyopening"
        element={<Protected Component={PartyOpening} />}
      />
      {/* CBP Opening */}
      <Route
        path="/cbpopening"
        element={<Protected Component={CpbOpening} />}
      />
      {/* Stock Opening */}
      <Route
        path="/stockopening"
        element={<Protected Component={StockOpening} />}
      />
      <Route
        path="/yarnopening"
        element={<Protected Component={YarnOpening} />}
      />
      <Route
        path="/digopening"
        element={<Protected Component={DigOpening} />}
      />
      {/* Stock Opening */}
      <Route path="/poopening" element={<Protected Component={PoOpening} />} />

      <Route
        path="/fileuploader"
        element={<Protected Component={FileUploader} />}
      />
    </Routes>
  );
}
