import React, { useContext, useEffect } from 'react';
import Select from 'react-select';
import { GlobalData } from '../GlobalData.js';
import { getCurBranchId, getCurBranchName } from '../Shared_Components/Util.js';

export default function BranchList() {

    const contextdata = useContext(GlobalData)
    const currentTheme = localStorage.getItem("theme") || "light";

    useEffect(() => {
        contextdata.setCurBranch({
            BranchId: getCurBranchId(),
            BranchName: getCurBranchName()
        })
        // eslint-disable-next-line
    }, [])

    function HandleSelect(e) {
        if (e === null) {
            e = { value: '', label: '' }
            localStorage.setItem('BranchId', 0);
            return;
        }

        localStorage.setItem('BranchId', e.value);
        localStorage.setItem('BranchName', e.label);
        contextdata.setCurBranch({ BranchId: e.value, BranchName: e.label });
        window.location.reload();
    }

    if (!contextdata?.companies[0]?.IsMultiBranch) return (<></>)

        const customStyles = {
            control: (provided) => ({
              ...provided,
              minHeight: "27px",
              height: "27px",
              background: currentTheme === "dark" ? '#2c3e50' : '#fff',
              borderColor: currentTheme === "dark" ? '#fff' : '#c9c9c9',
        }),
            indicatorsContainer: (provided) => ({
              ...provided,
              height: "27px",
            }),
            menu: (provided) => ({
              ...provided,
              backgroundColor: currentTheme === "dark" ? '#34495e' : '#fff',
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: currentTheme === "dark" 
                ? state.isFocused ? '#4a6785' : '#34495e'
                : state.isFocused ? '#f0f0f0' : '#fff',
              color: currentTheme === "dark" ? '#fff' : '#000',
            }),
            singleValue: (provided) => ({
              ...provided,
              color: currentTheme === "dark" ? '#fff' : '#000',
            }),
            input: (provided) => ({
              ...provided,
              margin: '0px',
            }),
          };
        
    return (
        <Select
            value={{value: contextdata?.curBranch?.BranchId, label: contextdata?.curBranch?.BranchName}}
            onChange={(e) => HandleSelect(e)}
            options={contextdata.branches && contextdata.branches.length > 0 ? contextdata.branches : ''}
            isLoading={false}
            isSearchable={true}
            className='zIndex-5'
            styles={customStyles}
        // autoFocus={true}
        />
    );
}
